var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "lawyercost",
      staticStyle: {
        height: "100%",
        "padding-bottom": "100px",
        "box-size": "border-box",
        "padding-top": "1.173333rem",
      },
    },
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: "律师费计算", fixed: "" },
          slot: "header",
        },
        [
          _c("van-icon", {
            staticClass: "arrow",
            attrs: { slot: "left", name: "arrow-left" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.back.apply(null, arguments)
              },
            },
            slot: "left",
          }),
          _c(
            "span",
            {
              attrs: { slot: "right" },
              on: {
                click: function ($event) {
                  _vm.popupHistory = true
                },
              },
              slot: "right",
            },
            [_vm._v("历史")]
          ),
        ],
        1
      ),
      _c("van-field", {
        attrs: {
          label: "城市",
          value: _vm.from.province,
          readonly: "",
          clickable: "",
          placeholder: "点击选择城市",
        },
        on: {
          click: function ($event) {
            _vm.showPickerCity = true
          },
        },
      }),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.showPickerCity,
            callback: function ($$v) {
              _vm.showPickerCity = $$v
            },
            expression: "showPickerCity",
          },
        },
        [
          _c("van-picker", {
            attrs: {
              title: "请选择城市",
              "show-toolbar": "",
              columns: _vm.cityList,
            },
            on: {
              confirm: _vm.chooseCityFn,
              cancel: function ($event) {
                _vm.showPickerCity = false
              },
            },
          }),
        ],
        1
      ),
      _vm.from.province
        ? _c("van-field", {
            attrs: {
              label: "类型",
              value: _vm.from.caseType,
              readonly: "",
              clickable: "",
              placeholder: "点击选择案件类型",
            },
            on: {
              click: function ($event) {
                _vm.showPickerType = true
              },
            },
          })
        : _vm._e(),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.showPickerType,
            callback: function ($$v) {
              _vm.showPickerType = $$v
            },
            expression: "showPickerType",
          },
        },
        [
          _c("van-picker", {
            attrs: {
              title: "请选择案件类型",
              "show-toolbar": "",
              columns: _vm.typeList,
            },
            on: {
              confirm: _vm.chooseTypeFn,
              cancel: function ($event) {
                _vm.showPickerType = false
              },
            },
          }),
        ],
        1
      ),
      _vm.showStage
        ? _c("van-field", {
            attrs: {
              label: "阶段",
              value: _vm.from.caseStage,
              readonly: "",
              clickable: "",
              placeholder: "点击选择案件阶段",
            },
            on: {
              click: function ($event) {
                _vm.showPickerStage = true
              },
            },
          })
        : _vm._e(),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.showPickerStage,
            callback: function ($$v) {
              _vm.showPickerStage = $$v
            },
            expression: "showPickerStage",
          },
        },
        [
          _c("van-picker", {
            attrs: {
              title: "请选择案件阶段",
              "show-toolbar": "",
              columns: _vm.stagesList,
            },
            on: {
              confirm: _vm.chooseStagesFn,
              cancel: function ($event) {
                _vm.showPickerStage = false
              },
            },
          }),
        ],
        1
      ),
      _vm.from.caseType && !_vm.showStage
        ? _c("van-field", {
            attrs: {
              placeholder: "请输入(不涉及财产)",
              type: "number",
              label: "标的额",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "button",
                  fn: function () {
                    return [_vm._v("元")]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1488816072
            ),
            model: {
              value: _vm.from.targetAmount,
              callback: function ($$v) {
                _vm.$set(_vm.from, "targetAmount", $$v)
              },
              expression: "from.targetAmount",
            },
          })
        : _vm._e(),
      _c(
        "van-button",
        {
          staticClass: "select_btn",
          attrs: { disabled: !_vm.showBtn, type: "info", loading: _vm.loading },
          on: { click: _vm.calcMoneyFn },
        },
        [_vm._v("计算律师费")]
      ),
      _c(
        "van-popup",
        {
          staticClass: "popup",
          attrs: { position: "right" },
          model: {
            value: _vm.popup,
            callback: function ($$v) {
              _vm.popup = $$v
            },
            expression: "popup",
          },
        },
        [
          _c(
            "fb-header",
            { attrs: { title: "律师费计算结果", fixed: "" } },
            [
              _c("van-icon", {
                staticClass: "arrow",
                attrs: { slot: "left", name: "arrow-left" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.popup = false
                  },
                },
                slot: "left",
              }),
            ],
            1
          ),
          _c(
            "van-collapse",
            {
              attrs: {
                error: _vm.error,
                "error-text": "请求失败，点击重新加载",
              },
              on: {
                "update:error": function ($event) {
                  _vm.error = $event
                },
              },
              model: {
                value: _vm.activeNames,
                callback: function ($$v) {
                  _vm.activeNames = $$v
                },
                expression: "activeNames",
              },
            },
            [
              _c(
                "van-collapse-item",
                { attrs: { title: "详情", name: "1" } },
                _vm._l(
                  _vm.result.lawyerFeeStandardTextArr,
                  function (item, index) {
                    return _c("div", { key: index }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(item.startText) +
                            "      " +
                            _vm._s(item.endText)
                        ),
                      ]),
                    ])
                  }
                ),
                0
              ),
              _c("van-collapse-item", { attrs: { title: "依据", name: "2" } }, [
                _vm._v(" " + _vm._s(_vm.yjtext) + " "),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "price" }, [
            _c("span", [_vm._v("律师费:")]),
            _vm.result.minResult != _vm.result.maxResult
              ? _c("span", [
                  _vm._v(
                    _vm._s(_vm.result.minResult) +
                      " 至 " +
                      _vm._s(_vm.result.maxResult) +
                      " 元"
                  ),
                ])
              : _c("span", [_vm._v(_vm._s(_vm.result.minResult) + " 元")]),
          ]),
          _c(
            "div",
            { staticClass: "popup_btn" },
            [
              _c(
                "van-button",
                {
                  staticStyle: { width: "100%" },
                  attrs: { type: "primary" },
                  on: { click: _vm.copy },
                },
                [_vm._v("复制结果")]
              ),
              _c(
                "van-button",
                {
                  staticStyle: { width: "100%" },
                  attrs: { type: "info" },
                  on: {
                    click: function ($event) {
                      _vm.popup = false
                    },
                  },
                },
                [_vm._v("返回计算")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "fb-header",
        {
          staticClass: "historyHeader",
          style: { display: _vm.popupHistory ? "flex" : "none" },
          attrs: { title: "历史记录", fixed: "" },
        },
        [
          _c("van-icon", {
            staticClass: "arrow",
            attrs: { slot: "left", name: "arrow-left" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.popupHistory = false
              },
            },
            slot: "left",
          }),
          _c(
            "span",
            {
              attrs: { slot: "right" },
              on: { click: _vm.deleteAllFn },
              slot: "right",
            },
            [_vm._v("清空")]
          ),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          staticClass: "popup",
          attrs: { position: "right" },
          model: {
            value: _vm.popupHistory,
            callback: function ($$v) {
              _vm.popupHistory = $$v
            },
            expression: "popupHistory",
          },
        },
        [
          _c(
            "van-pull-refresh",
            {
              on: { refresh: _vm.onRefresh },
              model: {
                value: _vm.refreshing,
                callback: function ($$v) {
                  _vm.refreshing = $$v
                },
                expression: "refreshing",
              },
            },
            [
              _c(
                "van-list",
                {
                  attrs: {
                    finished: _vm.finished,
                    "finished-text": "没有更多了",
                  },
                  on: { load: _vm.getList },
                  model: {
                    value: _vm.listloading,
                    callback: function ($$v) {
                      _vm.listloading = $$v
                    },
                    expression: "listloading",
                  },
                },
                _vm._l(_vm.list, function (item) {
                  return _c(
                    "van-swipe-cell",
                    {
                      key: item.id,
                      attrs: {
                        "before-close": _vm.beforeClose,
                        "data-id": item.id,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "right",
                            fn: function () {
                              return [
                                _c("van-button", {
                                  staticClass: "delete-button",
                                  attrs: {
                                    loading: _vm.deleteBtnLoading,
                                    "loading-type": "spinner",
                                    square: "",
                                    text: "删除",
                                    type: "danger",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c("van-cell", {
                        attrs: { title: item.title, value: item.createTime },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }