var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "van-field",
        {
          attrs: { id: "input" },
          on: { input: _vm.checkvalue },
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v
            },
            expression: "value",
          },
        },
        [
          _vm.showButton
            ? _c(
                "van-button",
                {
                  staticClass: "confirm",
                  staticStyle: { heigth: "1rem" },
                  attrs: { slot: "button", size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.confirm(_vm.value)
                    },
                  },
                  slot: "button",
                },
                [_vm._v("确定")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.showList
        ? _c(
            "div",
            [
              _c(
                "van-list",
                {
                  attrs: {
                    finished: _vm.finished,
                    "finished-text": "没有更多了",
                  },
                  on: {
                    load: function ($event) {
                      return _vm.load(_vm.value)
                    },
                  },
                  model: {
                    value: _vm.loading,
                    callback: function ($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading",
                  },
                },
                _vm._l(_vm.list, function (i) {
                  return _c("van-cell", {
                    key: i.id,
                    ref: "office",
                    refInFor: true,
                    attrs: { value: i.name },
                    on: {
                      click: function ($event) {
                        return _vm.lawfirms(i.name, i.id)
                      },
                    },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "van-button",
        {
          staticClass: "goback",
          attrs: { type: "info" },
          on: { click: _vm.goback },
        },
        [_vm._v("返回")]
      ),
      _vm.bool
        ? _c("nomessage", { attrs: { num: _vm.num, length: _vm.length } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }