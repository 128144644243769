var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("van-cell", [
        _c("div", { staticClass: "intro" }, [
          _vm._v(
            " 该律所尚未收录，请填写全称，系统将自动收录。系统会进行核实，请正确填写真实的律所名称。 "
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }