<template>
  <div
    class="lawyercost"
    style="
      height: 100%;
      padding-bottom: 100px;
      box-size: border-box;
      padding-top: 1.173333rem;
    "
  >
    <!--标题部分-->
    <fb-header slot="header" title="律师费计算" fixed>
      <van-icon
        name="arrow-left"
        class="arrow"
        @click.stop="back"
        slot="left"
      />
      <span slot="right" @click='popupHistory = true'>历史</span>
    </fb-header>
    <!--表单部分-->
    <!--城市-->
    <van-field
      label="城市"
      :value="from.province"
      readonly
      clickable
      placeholder="点击选择城市"
      @click="showPickerCity = true"
    />
    <van-popup v-model="showPickerCity" position="bottom">
      <van-picker
        title="请选择城市"
        show-toolbar
        :columns="cityList"
        @confirm="chooseCityFn"
        @cancel="showPickerCity = false"
      />
    </van-popup>
    <!--类型-->
    <van-field
      v-if="from.province"
      label="类型"
      :value="from.caseType"
      readonly
      clickable
      placeholder="点击选择案件类型"
      @click="showPickerType = true"
    />
    <van-popup v-model="showPickerType" position="bottom">
      <van-picker
        title="请选择案件类型"
        show-toolbar
        :columns="typeList"
        @confirm="chooseTypeFn"
        @cancel="showPickerType = false"
      />
    </van-popup>
    <!--案件阶段 - 刑事案件-->
    <van-field
      v-if="showStage"
      label="阶段"
      :value="from.caseStage"
      readonly
      clickable
      placeholder="点击选择案件阶段"
      @click="showPickerStage = true"
    />
    <van-popup v-model="showPickerStage" position="bottom">
      <van-picker
        title="请选择案件阶段"
        show-toolbar
        :columns="stagesList"
        @confirm="chooseStagesFn"
        @cancel="showPickerStage = false"
      />
    </van-popup>
    <!--标的额-->
    <van-field
      v-if="from.caseType && !showStage"
      placeholder="请输入(不涉及财产)"
      v-model="from.targetAmount"
      type="number"
      label="标的额"
    >
      <template #button>元</template>
    </van-field>
    <!--查询律师费 按钮-->
    <van-button
      :disabled="!showBtn"
      type="info"
      class="select_btn"
      @click="calcMoneyFn"
      :loading="loading"
      >计算律师费</van-button
    >
    <!--弹出层 计算律师费-->
    <van-popup
      class='popup'
      v-model="popup"
      position="right"
    >
      <!--标题部分-->
      <fb-header title="律师费计算结果" fixed>
        <van-icon
          name="arrow-left"
          class="arrow"
          @click.stop="popup = false"
          slot="left"
        />
      </fb-header>
      <van-collapse v-model="activeNames" :error.sync="error" error-text="请求失败，点击重新加载">
        <van-collapse-item title="详情" name="1">
          <!--详情部分-->
          <div v-for="(item, index) in result.lawyerFeeStandardTextArr" :key="index">
            <span>{{ item.startText }} &nbsp; &nbsp; &nbsp;{{ item.endText }}</span>
          </div>
        </van-collapse-item>
        <van-collapse-item title="依据" name="2">
          <!--依据部分-->
          {{yjtext}}
        </van-collapse-item>
      </van-collapse>
      <!--价格部分-->
      <div class='price'>
        <span>律师费:</span>
        <span v-if="result.minResult != result.maxResult">{{ result.minResult }}&nbsp;至&nbsp;{{ result.maxResult }}&nbsp;元</span>
        <span v-else>{{ result.minResult }} 元</span>
      </div>
      <!--按钮部分-->
      <div class='popup_btn'>
        <van-button type='primary' style='width: 100%' @click='copy'>复制结果</van-button>
<!--        <van-button type='primary' style='width: 100%' @click='share'>分享结果</van-button>-->
        <van-button type='info' style='width: 100%' @click='popup = false'>返回计算</van-button>
      </div>
    </van-popup>
    <!--弹出层 历史记录-->
    <!--标题部分-->
    <fb-header title="历史记录" fixed class='historyHeader' :style='{ display: popupHistory ? "flex":"none" }'>
      <van-icon
        name="arrow-left"
        class="arrow"
        @click.stop="popupHistory = false"
        slot="left"
      />
      <span slot="right" @click='deleteAllFn'>清空</span>
    </fb-header>
    <van-popup class='popup' v-model="popupHistory" position="right">
      <!--列表部分-->
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="listloading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getList"
        >
          <van-swipe-cell v-for="item in list" :key="item.id" :before-close="beforeClose" :data-id='item.id'>
            <van-cell :title="item.title" :value='item.createTime' />
            <template #right>
              <van-button :loading='deleteBtnLoading' loading-type="spinner" square text="删除" type="danger" class="delete-button"/>
            </template>
          </van-swipe-cell>
        </van-list>
      </van-pull-refresh>
    </van-popup>
  </div>
</template>

<script>
import goBackMixin from '@mixins/goBack'
export default {
  name: 'index',
  mixins: [goBackMixin],
  created() {
    this.loadCityList()
  },
  data() {
    return {
      from: {
        // 计算律师费 表单
        province: '', // 省份
        caseType: '', // 案件类型
        targetAmount: '', // 标的额
        caseStage: '', // 案件阶段
      },
      showPickerCity: false, // 选择城市
      showPickerType: false,
      showPickerStage: false,
      typeList: [], // 案件类型
      cityList: [], // 城市列表
      stagesList: [], // 案件阶段
      popup: false, // 计算律师费 结果
      popupHistory: false, // 历史记录
      loading: false,
      result: {},
      activeNames: ['1'],
      yjtext: '',
      // 历史记录 部分
      list: [],
      listloading: false,
      finished: false,
      refreshing: false,
      historytotal: 0,
      deleteBtnLoading: false,
      pageNo: 0,
      error: false
    }
  },
  computed: {
    showStage() {
      return this.from.caseType === '刑事案件'
    },
    showBtn() {
      return this.from.caseStage || this.from.targetAmount
    },
  },
  methods: {
    // 返回上一个页面
    back() {
      if (this.$router.length <= 1) {
        this.backToNative()
      } else {
        history.back(-1)
      }
    },
    // 选择的城市改变
    chooseCityFn(value, index) {
      this.from.caseType = ''
      this.from.targetAmount = ''
      this.from.caseStage = ''
      if (!value) return
      this.from.province = value
      this.showPickerCity = false
      this.loadTypeList()
    },
    // 加载城市列表
    async loadCityList() {
      const { data: res } = await this.$axios.post(
        `${this.$base}/document/basicAdministrativeRegion/queryByParams?name=&pageNum=0&level=1&pageSize=40`,
        {
          pageNum: 0,
          level: 1,
          pageSize: 40,
        }
      )
      if (res.code !== '200') return this.$notify(res.message)
      const cityList = []
      res.data.items.forEach((ele) => {
        cityList.push(ele.name)
      })
      this.cityList = cityList
    },
    // 选择案件类型
    chooseTypeFn(value) {
      this.from.targetAmount = ''
      this.from.caseStage = ''
      this.from.caseType = value
      this.showPickerType = false
    },
    // 加载案件类型
    async loadTypeList() {
      if (!this.from.province) {
        this.typeList = []
        return
      }
      const { data: res } = await this.$axios.get(
        `${this.$base}/management/lawyer/service-fee/standard/getListStandardByProvince`,
        {
          params: { province: this.from.province },
        }
      )
      if (res.code !== 200) return this.$notify(res.message)
      this.typeList = res.data.caseTypes
      this.stagesList = res.data.caseStages
    },
    // 选择案件阶段
    chooseStagesFn(value) {
      this.showPickerStage = false
      this.from.caseStage = value
    },
    async calcMoneyFn() {
      this.loading = true
      // 计算律师费
      const res = await this.$axios.post(
        `${this.$base}/management/lawyer/service-fee/standard/getLawyerServiceFee`,
        {
          ...this.clearObj(this.from),
        }
      )
      this.loading = false
      if (res.data.code !== 200) {
        this.$notify({ type: 'warning', message: res.data.message })
        return
      }
      // 计算
      const lawyerFeeStandards = res.data.data.lawyerFeeStandards
      lawyerFeeStandards.push(res.data.data.lawyerFeeStandard)
      const targetAmount = Number(this.from.targetAmount)
      let endIndex = -1
      let lastEndMoney = 0 // 满足上一阶段的最大
      let lastMoney = 0 // 最后一阶段的剩余
      let rangeMoney = 0 // 金额间隔段
      const lawyerFeeStandardTextArr = lawyerFeeStandards.filter((v) => {
        if (targetAmount) {
          return Number(v.propertyAmountMin) < targetAmount
        } else {
          return v
        }
      })
      lawyerFeeStandardTextArr.forEach((item, index) => {
        const chargeRatioMin = Number(item.chargeRatioMin)
        const chargeRatioMax = Number(item.chargeRatioMax)
        const chargeRatioBase = Number(item.chargeRatioBase)
        const chargeAmountMin = Number(item.chargeAmountMin)
        const chargeAmountMax = Number(item.chargeAmountMax)
        const propertyAmountMax = Number(item.propertyAmountMax)
        const propertyAmountMin = Number(item.propertyAmountMin)
        if (propertyAmountMax > targetAmount) {
          endIndex = index
          if (endIndex > 0) {
            lastEndMoney =
              lawyerFeeStandards[endIndex - 1].propertyAmountMax &&
              Number(lawyerFeeStandards[endIndex - 1].propertyAmountMax)
          }
          lastMoney = targetAmount - lastEndMoney
        }
        rangeMoney = propertyAmountMax - propertyAmountMin + 1
        if (propertyAmountMin && propertyAmountMax) {
          if (propertyAmountMax === -1) {
            item.startText = `涉及财产金额 ${propertyAmountMin}元以上`
          } else {
            item.startText = `涉及财产金额 ${propertyAmountMin} 至 ${propertyAmountMax} 元`
          }
        } else {
          item.startText = ''
        }
        if (chargeRatioBase) {
          item.endText = `收费标准比率固定${(chargeRatioBase * 100).toFixed(
            1
          )}% `
          if (index === endIndex) {
            item.moneyRange = [
              parseInt(chargeRatioBase * lastMoney),
              parseInt(chargeRatioBase * lastMoney),
            ]
          } else {
            item.moneyRange = [
              parseInt(chargeRatioBase * rangeMoney),
              parseInt(chargeRatioBase * rangeMoney),
            ]
          }
        } else if (chargeRatioMin && chargeRatioMax) {
          item.endText = `收费标准比率${(chargeRatioMin * 100).toFixed(
            1
          )}% 至 ${(chargeRatioMax * 100).toFixed(1)}%`
          if (index === endIndex) {
            item.moneyRange = [
              parseInt(chargeRatioMin * lastMoney),
              parseInt(chargeRatioMax * lastMoney),
            ]
          } else {
            item.moneyRange = [
              parseInt(chargeRatioMin * rangeMoney),
              parseInt(chargeRatioMax * rangeMoney),
            ]
          }
        } else if (chargeRatioMin && !chargeRatioMax) {
          item.endText = `收费标准比率固定${(chargeRatioMin * 100).toFixed(
            1
          )}% `
          if (index === endIndex) {
            item.moneyRange = [
              parseInt(chargeRatioMin * lastMoney),
              parseInt(chargeRatioMin * lastMoney),
            ]
          } else {
            item.moneyRange = [
              parseInt(chargeRatioMin * rangeMoney),
              parseInt(chargeRatioMin * rangeMoney),
            ]
          }
        } else if (!chargeRatioMin && chargeRatioMax) {
          item.endText = `收费标准比率固定${(chargeRatioMax * 100).toFixed(
            1
          )}% `
          if (index === endIndex) {
            item.moneyRange = [
              parseInt(chargeRatioMax * lastMoney),
              parseInt(chargeRatioMax * lastMoney),
            ]
          } else {
            item.moneyRange = [
              parseInt(chargeRatioMax * rangeMoney),
              parseInt(chargeRatioMax * rangeMoney),
            ]
          }
        } else if (chargeAmountMin || chargeAmountMax) {
          item.endText = `固定收费${chargeAmountMin}${
            chargeAmountMax ? '至' + chargeAmountMax : ''
          }元`
          if (index === endIndex) {
            item.moneyRange = [
              chargeAmountMin || chargeAmountMax,
              chargeAmountMax || chargeAmountMin,
            ]
          } else {
            item.moneyRange = [
              chargeAmountMin || chargeAmountMax,
              chargeAmountMax || chargeAmountMin,
            ]
          }
        }
        console.log(item, item.startText, item.endText, item.moneyRange)
      })
      let maxResult = 0
      let minResult = 0
      window.console.log('lawyerFeeStandardTextArr', lawyerFeeStandardTextArr)
      lawyerFeeStandardTextArr.forEach((v) => {
        if (v.moneyRange) {
          minResult += v.moneyRange[0]
          maxResult += v.moneyRange[1]
        }
      })
      this.result = {
        maxResult,
        minResult,
        lawyerFeeStandardTextArr,
      }
      window.console.log(this.result)
      this.yjtextFn()
      this.popup = true
      this.handleAddHistory()
    },
    // 依据文字
    yjtextFn() {
      const { province, caseType, caseStage } = this.from
      let text = ''
      text += `《${province}律师服务收费标准》${caseType}`
      text += `${caseStage ? caseStage + '，' : '，'}`
      if (this.result) {
        const { lawyerFeeStandardTextArr } = this.result
        lawyerFeeStandardTextArr.forEach((v) => {
          text += v.startText ? v.startText + ':' : ''
          text += v.endText + ';'
        })
      }
      this.yjtext = text
    },
    copy() {
      let text = '律师费  \n' + this.handleText()
      const url = window.location.href
      // const res = await longUrl2ShortUrl(url)
      // if (res.status === 200) {
      //   url = res.data
      text += '\n' + url
      // }
      const transfer = document.createElement('input')
      document.body.appendChild(transfer)
      transfer.value = text // 这里表示想要复制的内容
      transfer.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
      }
      transfer.blur()
      document.body.removeChild(transfer)
      this.$notify({ type: 'success', message: '已复制，请粘贴到QQ或微信中' })
    },
    // 点击分享
    share() {
      let text = window.location.origin + '/tool/lawyerFee'
      if (this.result) {
        const { province, caseType, targetAmount, caseStage } = this.from
        const strTargetAmount = targetAmount
          .toString()
          .replace(/(^\s+)|(\s+$)/g, '')
          .replace(/\s/g, '')
        const obj = {
          province,
          caseType,
          targetAmount: caseStage ? 0 : strTargetAmount,
          caseStage,
        }
        text += `?data=${JSON.stringify(obj)}`
      }
      const transfer = document.createElement('input')
      document.body.appendChild(transfer)
      transfer.value = text // 这里表示想要复制的内容
      transfer.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
      }
      transfer.blur()
      document.body.removeChild(transfer)
      this.$notify({ type: 'success', message: '已复制，请粘贴到QQ或微信中' })
    },
    // 添加一条记录
    handleAddHistory() {
      const type = 'lawyerFee'
      const { province, caseType, targetAmount, caseStage } = this.from
      const strTargetAmount = targetAmount
        .toString()
        .replace(/(^\s+)|(\s+$)/g, '')
        .replace(/\s/g, '')
      const conditionObj = {
        province,
        caseType,
        targetAmount: caseStage ? 0 : strTargetAmount,
        caseStage,
        result: this.result,
      }
      let title = ' ' + province + ' ' + caseType + ' '
      title += caseStage || strTargetAmount + '元'
      title += ' 律师费'
      if (this.result && this.result.toString().trim() !== '0') {
        this.$axios
          .post(`${this.$base}/management/utils/history/add`, {
            type,
            condition: JSON.stringify(conditionObj),
            title,
          })
          .then((res) => {
            if (res.data.code === 200) {
              // this.$bus.$emit('add-history-suc', 'lawyerFee')
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    async getList(pageNo, size = 10) {
      try {
        if (this.refreshing) {
          this.list = []
          this.refreshing = false
        }
        if (!pageNo) {
          pageNo = this.pageNo + 1
        } else {
          this.pageNo = pageNo
        }
        const { data: res } = await this.$axios.get(`${this.$base}/management/utils/history/queryByUserId`, {
          params: {
            type: 'lawyerFee',
            pageNo: pageNo,
            pageSize: size
          }
        })
        if (res.code !== 200) return this.$notify(res.data.message)
        if (pageNo === 1 || pageNo === '1') this.list = []
        this.historytotal = res.data.totalCount - 0 // 赋值总条数
        this.list = [...this.list, ...res.data.content] // 赋值数据
        this.pageNo = res.data.pageNo - 0
        this.listloading = false
        window.console.log('this.list.length', this.list.length)
        window.console.log('this.historytotal', this.historytotal)
        if (this.list.length === this.historytotal) {
          this.finished = true
        }
        window.console.log(res)
      } catch (e) {
        this.$notify('加载失败')
        this.listloading = false
        this.finished = true
      }
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.listloading = true
      this.pageNo = 0
      this.getList()
    },
    async deleteoneFn(id, instance) {
      window.console.log('delete id', id)
      this.deleteBtnLoading = true
      // 执行删除
      const { data: res } = await this.$axios.get(`${this.$base}/management/utils/history/delOne?id=${id}`)
      this.deleteBtnLoading = false
      window.console.log(res)
      window.console.log(this.$notify('aa'))
      if (res.code !== 200) return this.$notify(res.message)
      this.$notify({ type: 'success', message: '操作成功' })
      instance.close()
      this.getList(1, this.list.length)
    },
    deleteAllFn() {
      if (!this.list.length) return this.$notify('暂无可删除的数据')
      this.$dialog.confirm({
        title: '提示',
        message: '是否确认删除全部记录?此操作不可恢复!'
      }).then(async() => {
        const { data: res } = await this.$axios.get(`${this.$base}/management/utils/history/delAll?type=lawyerFee`)
        if (res.code !== 200) return this.$notify(res.message)
        this.$notify({ type: 'success', message: res.message })
        this.getList(1, this.list.length)
      }).catch(() => {})
    },
    deleteAllDataFn() {},
    beforeClose({ instance, position }) {
      window.console.log('position', position)
      const id = instance.$el.getAttribute('data-id')
      window.console.log('instance', id)
      switch (position) {
        case 'right':
          this.deleteoneFn(id, instance)
          break
        case 'outside':
          instance.close()
          break
      }
    },
    // 处理导出和复制的文字
    handleText() {
      const { province, caseType, targetAmount, caseStage } = this.from
      const StrTargetAmount = targetAmount
        .toString()
        .replace(/(^\s+)|(\s+$)/g, '')
        .replace(/\s/g, '')
      let text = ''
      text += `${province}-${caseType}，`
      text += `${caseStage ? '案件阶段：' + caseStage + '  \n' : ''}`
      text += `${StrTargetAmount ? '标的额：' + StrTargetAmount + '元  \n' : ''}`
      if (this.result) {
        let jdMoney = ''
        const { minResult, maxResult } = this.result
        if (minResult === maxResult) {
          jdMoney = minResult
        } else {
          jdMoney = `${minResult} 至 ${maxResult}`
        }
        text += `律师费：${jdMoney}元  \n`
        text += '依据： \n'
        text += this.yjtext
      }
      return text
    },
    clearObj(obj) {
      const newObj = {}
      for (const key in obj) {
        if (obj[key] !== '') {
          newObj[key] = obj[key]
        }
      }
      return newObj
    },
  },
  watch: {
    popupHistory(val) {
      if (!val) return
      this.getList(1, 10)
    }
  }
}
</script>

<style scoped lang="stylus">
.lawyercost
  background: rgb(247, 248, 250)
.head
  display flex
  justify-content space-between
  align-items center
  border-bottom-color green
  .left
    font-size 0.8rem
    font-weight 900
    display flex
    align-items center
    .arrow
      font-size 15px
      padding-left 0.2rem
.btn_area
  width: 100%
  overflow hidden
.select_btn
  margin 0 auto
  margin-top .5rem
  display block
  width: 95%
.popup
  width: 100%
  height: 100%
  padding-top: 1.173333rem
  .price,.popup_btn
    padding 0 .15rem
  .price
    display: flex
    justify-content: flex-end
    color #ee0a24
    font-weight: bold
    font-size .46rem
    margin-top .15rem
    margin-bottom .15rem
  .popup_btn
    .van-button
      margin-bottom .15rem
.delete-button
  //width: 100%;
  width: 1.599733333333333rem
  height: 100%
.historyHeader
  position fixed
  width: 100%
  z-index 110
  display none
::v-deep .van-overlay
  z-index 100 !important
::v-deep .popup
  z-index 101 !important
::v-deep .van-pull-refresh__track
  min-height 'calc(100vh - %s)' % 1.17333rem
</style>
