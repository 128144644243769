<template>
  <div>
    <van-cell>
      <div class="intro">
        该律所尚未收录，请填写全称，系统将自动收录。系统会进行核实，请正确填写真实的律所名称。

      </div>
    </van-cell>
  </div>
</template>
<script>
export default {
  name: 'nomessage',
  props: ['num', 'length'],

  data () {
    return {
      bool: false
    }
  },
  mounted () {

  }
}
</script>
<style lang="stylus" scoped>
.intro
  color #FB6548
</style>
