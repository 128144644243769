<template>
  <div>
    <van-field v-model="value"
               id="input"
               @input="checkvalue">
      <van-button slot="button"
                  size="small"
                  type="primary"
                  v-if="showButton"
                  style="heigth:1rem"
                  class="confirm"
                  @click="confirm(value)">确定</van-button>
    </van-field>
    <div v-if="showList">

      <van-list v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="load(value)">
        <van-cell v-for=" i in list"
                  :key="i.id"
                  @click="lawfirms(i.name,i.id)"
                  ref="office"
                  :value="i.name" />

      </van-list>
    </div>

    <van-button type="info"
                class="goback"
                @click="goback">返回</van-button>
    <nomessage :num='num'
               :length='length'
               v-if="bool"></nomessage>

  </div>
</template>

<script>
import { caseBase } from '~api-config'
import nomessage from './nomessage'
export default {
  name: 'institutions',
  components: {
    nomessage
  },
  data () {
    return {
      value: '',
      lawFirms: [],
      inputFirms: [], // 输入时获取的列表
      num: 0,
      length: 10,
      array: null,
      showButton: false,
      list: [],
      loading: false,
      finished: false,
      pageNum: 1,
      total: 0,
      inputsvalue: [],
      bool: false,
      valueIsNull: true,
      isvalueNull: false,
      showList: true
    }
  },
  methods: {
    loadfun (val, type) {
      var pattern = /^[\u2E80-\u9FFF]+$/

      this.$axios.get(`${caseBase}/lts/Lawfirm/lawfirmlist`, { params: { city: this.$route.params.city, pageNum: type === 1 ? 1 : this.pageNum, name: val } })
        .then(res => {
          if (res.data.code === '200') {
            // if (type === 1)
            this.pageNum++
            if (res.data.data.lawFirms.length >= 1) {
              this.showList = true
              this.bool = false

              for (const j of res.data.data.lawFirms) {
                this.list.push(j)
              }

              this.loading = false
            } else {
              // this.finished = false
              console.log(res)

              this.list = []
              const arr = this.value.split('')
              if (arr.length > 8 && arr[arr.length - 1] === '所') {
                this.showButton = true
              } else {
                this.showButton = false
              }
              // console.log('arr=====>', arr)
              this.total = 0
              for (const i of arr) {
                if (pattern.test(i)) {
                  this.total++
                  if (this.total >= 2) {
                    this.bool = true
                    this.showList = false
                  }
                } else {
                  this.bool = false
                  this.showList = true
                }
              }
              // console.log(this.total)
            }

            if (this.list.length < res.data.data.total) {
              this.finished = false
            } else {
              this.finished = true
            }
          }
        })
    },
    load (val, type) {
      this.fun(this.loadfun, 200, val, type)
    },
    fun (handler, time, ...args) {
      clearTimeout(this.time)
      this.time = setTimeout(() => {
        handler.apply(this, args)
      }, time)
    },
    // 输入框输入时获取相关列表
    checkvalue () {
      this.lawFirms = []
      this.list = []
      this.pageNum = 1
      // console.log('$$$$$$', this.pageNum)
      // if (this.list.length === 0) {
      this.load(this.value, 1)
      // }
    },
    // 点击提示框获取内容
    lawfirms (value, lawyerid) {
      // console.log(value)
      this.$store.commit('caseDetail/setorganisename', value)
      this.$store.commit('caseDetail/setlawyerid', lawyerid)
      history.back(-1)
    },
    inputfirms (value) {
      this.$store.commit('/caseDetail/setorganisename', value)
      history.back(-1)
    },
    confirm (val) {
      this.$store.commit('caseDetail/setorganisename', val)
      history.back(-1)
    },
    goback () {
      // console.log(this.$route.params.organizename)
      this.$store.commit('caseDetail/setorganisename', this.$route.params.organizename)
      history.back(-1)
    }
  },
  mounted () {
    setTimeout(() => {
      document.getElementById('input').focus()
    }, 1000)
    // this.showlist()
  },
  created () {
    this.length = (this.lawFirms.length * 2)
    console.log(this.$route.params.city)
  }

}
</script>
<style lang="stylus" scoped>
// .van-cell__value
// span
// color red
.confirm
  height 0.7rem
  line-height 0.7rem
  background #4B9EFB
.goback
  width 100%
  background #4B9EFB
  color white
  position fixed
  bottom 0
</style>
